import Button from "@mui/material/Button";
import React from "react";

export default function BtnCus(props) {
  
  return (
    <Button
      href={props.href}
      sx={{
        textTransform: "capitalize !important",
        boxShadow: "none",
        borderRadius: "20px",
        p: "4px 16px",
        height: "40px",
        fontSize: "14px",
        color: "white",
      }}
      variant={props.variant}
      color={props.color}
      style={props.style}
      size={props.size}
      onClick={props.onClick}
      type={props.type}
    >
      {props.text}
    </Button>
  );
}
