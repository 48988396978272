import "./Assets/scss/style.scss";
import React, { Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import Load from "./Components/Load";
import { useLoginStore } from "./Views/Auth/store";
import DetailPengguna from "./Views/Pages/Pengguna/DetailPengguna";
import MasterData from "./Views/Pages/MasterData/MasterData";
const Login = lazy(() => import("./Views/Auth/Login"));
const Index = lazy(() => import("./Views/Pages/Index"));
const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard"));
const Pengguna = lazy(() => import("./Views/Pages/Pengguna/Pengguna"));
const TambahPengguna = lazy(() => import("./Views/Pages/Pengguna/AddPengguna"));
const EditPengguna = lazy(() => import("./Views/Pages/Pengguna/EditPengguna"));
const EditRelawan = lazy(() => import("./Views/Pages/Pengguna/EditRelawan"));
const Laporan = lazy(() => import("./Views/Pages/Laporan/Laporan"));
const Kontak = lazy(() => import("./Views/Pages/Kontak/Kontak"));
const PerolehanSuara = lazy(() =>
  import("./Views/Pages/PerolehanSuara/PerolehanSuara")
);
const PeringkatCaleg = lazy(() =>
  import("./Views/Pages/PeringkatCaleg/PeringkatCaleg")
);
const PeringkatCalegInternal = lazy(() =>
  import("./Views/Pages/PeringkatCalegInternal/PeringkatCalegInternal")
);
const PeringkatKursi = lazy(() =>
  import("./Views/Pages/PeringkatKursi/PeringkatKursi")
);
const PeringkatPartai = lazy(() =>
  import("./Views/Pages/PeringkatPartai/PeringkatPartai")
);

const pages = [
  {
    el: <RoleComp el={<MasterData />} role={["superadmin"]} />,
    url: "master-data",
  },
  {
    el: <RoleComp el={<PerolehanSuara />} role={["superadmin"]} />,
    url: "perolehan-suara",
  },
  {
    el: <RoleComp el={<PeringkatCaleg />} role={["superadmin"]} />,
    url: "peringkat-caleg",
  },
  {
    el: <RoleComp el={<PeringkatPartai />} role={["superadmin"]} />,
    url: "peringkat-partai",
  },
  {
    el: <RoleComp el={<PeringkatCalegInternal />} role={["superadmin"]} />,
    url: "peringkat-internal-caleg",
  },
  {
    el: <RoleComp el={<PeringkatKursi />} role={["superadmin"]} />,
    url: "peringkat-kursi",
  },
  // {
  //   el: <RoleComp el={<EditTarget />} role={["superadmin"]} />,
  //   url: "data-target/edit/:id",
  // },
  { el: <Kontak />, url: "kontak" },
  { el: <Dashboard />, url: "dashboard" },
  { el: <RoleComp el={<Pengguna />} role={["superadmin"]} />, url: "pengguna" },
  { el: <RoleComp el={<Laporan />} role={["superadmin"]} />, url: "laporan" },
  {
    el: <RoleComp el={<TambahPengguna />} role={["superadmin"]} />,
    url: "pengguna/add",
  },
  {
    el: <RoleComp el={<EditPengguna />} role={["superadmin"]} />,
    url: "pengguna/edit/:id",
  },
  {
    el: <RoleComp el={<EditRelawan />} role={["superadmin"]} />,
    url: "pengguna/relawan-edit/:id",
  },
  {
    el: <RoleComp el={<DetailPengguna />} role={["superadmin"]} />,
    url: "pengguna/detail/:id",
  },
];

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role) ? (
    el
  ) : (
    <Navigate to="/loged/dashboard" replace />
  );
}

function App() {
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index />
              </RequireAuth>
            }
          >
            {pages.map((page) => (
              <Route path={page.url} element={page.el} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
