import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BtnCus from "../../../Components/Btn/BtnCus";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationCustom from "../../../Components/PaginationCustom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Swal from "sweetalert2";
import Searchbar from "../../../Components/Input/Searchbar";
import ModalAddCalon from "../../../Components/ModalAddCalon";
import { useCalonStore } from "./store/calon";
import ModalEditCalon from "../../../Components/ModalEditCalon";

export default function TabelCalon({ category }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataCr, setDataCr] = useState(null);
  const [state, action] = useCalonStore();
  const [, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);



  useEffect(() => {
    action.updateStore({
      page: 0,
      category: category,
    });
    action.loadData();
  }, [category]);

  function hapus() {
    handleClose();
    Swal.fire({
      title: "Yakin data ini Dihapus?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus",
      cancelButtonText: "Batal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.showLoading();
        const res = await action.deleteData(dataCr.id);
        if (res) {
          await action.loadData();
          Swal.fire({
            title: "Sukses hapus data",
            timer: 2000,
            icon: "success",
          });
        }
      }
    });
  }

  const handleClick = (event, dat) => {
    setAnchorEl(event.currentTarget);
    setDataCr(dat);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Searchbar action={action} state={state} />

        <div>
          <BtnCus
            variant="contained"
            text="Tambah Calon"
            onClick={() => setOpenAdd(true)}
          />
        </div>
      </div>
      <TableContainer sx={{ mt: "20px", borderRadius: "20px" }}>
        <Table>
          <TableHead sx={{ bgcolor: "#f2edf5" }}>
            <TableRow>
              {["No", "No urut", "Nama Calon/Paslon", "Aksi"].map((e, i) => (
                <TableCell key={i} align="left" sx={{ fontWeight: 700 }}>
                  {e}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!state.data ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  Load data . . .
                </TableCell>
              </TableRow>
            ) : state.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              state.data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{row?.noUrut ?? "-"} </TableCell>
                  <TableCell>{row?.name ?? "-"} </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={(event) => handleClick(event, row)}
                    >
                      <MoreHorizSharpIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <Menu
            elevation={2}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem
              className="menu-item-setting"
              onClick={() => setOpenEdit(true)}
              // onClick={() => navigate(`${pathname}/edit/${idCr}`)}
            >
              <EditOutlinedIcon sx={{ mr: "10px" }} /> Edit
            </MenuItem>
            <Divider
              sx={{
                marginTop: "2px !important",
                marginBottom: "2px !important",
              }}
            />
            <MenuItem className="menu-item-setting" onClick={hapus}>
              <DeleteOutlineOutlinedIcon sx={{ mr: "10px" }} /> Hapus
            </MenuItem>
          </Menu>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" my="20px">
        <PaginationCustom action={action} state={state} />
      </Box>
      <ModalAddCalon
        open={openAdd}
        handleClose={() => setOpenAdd(false)}
        category={category}
      />
      <ModalEditCalon
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        category={category}
        data={dataCr}
      />
    </div>
  );
}
