import { apiClient } from "../../../../service";

export const viewCalonApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      return `${key}=${filter[key]}`;
    })
    .join("&");
  return apiClient.get(`/calon/view?${queryString}`);
};
export const addCalonApi = (form) => apiClient.post(`/calon/create`, form);
export const deleteCalonApi = (id) => apiClient.post(`/calon/delete/${id}`);
export const detailCalonApi = (id) => apiClient.get(`/calon/detail/${id}`);
export const editCalonApi = (id, form) =>
  apiClient.post(`/calon/update/${id}`, form);

///caleg
export const viewCalegApi = (filter) => {
  const queryString = Object.keys(filter)
    .map((key) => {
      return `${key}=${filter[key]}`;
    })
    .join("&");
  return apiClient.get(`/caleg/view?${queryString}`);
};
export const addCalegApi = (form) => apiClient.post(`/caleg/create`, form);
export const deleteCalegApi = (id) => apiClient.post(`/caleg/delete/${id}`);
export const detailCalegApi = (id) => apiClient.get(`/caleg/detail/${id}`);
export const editCalegApi = (id, form) =>
  apiClient.post(`/caleg/update/${id}`, form);

///kec
export const viewKec = () => apiClient.get(`/kec/view`);
export const addKec = (form) => apiClient.post(`/kec/create`, form);
export const deleteKec = (id) => apiClient.post(`/kec/delete/${id}`);
export const editKec = (id, form) => apiClient.post(`/kec/update/${id}`, form);

///kel
export const viewKel = () => apiClient.get(`/kel/view`);
export const addKel = (form) => apiClient.post(`/kel/create`, form);
export const deleteKel = (id) => apiClient.post(`/kel/delete/${id}`);
export const editKel = (id, form) => apiClient.post(`/kel/update/${id}`, form);

///tps
export const viewTps = () => apiClient.get(`/tps/view`);

export const addTps = (form) => apiClient.post(`/tps/create`, form);
export const deleteTps = (id) => apiClient.post(`/tps/delete/${id}`);
export const editTps = (id, form) => apiClient.post(`/tps/update/${id}`, form);
