import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import { Divider, Stack } from "@mui/material";
import TextInput from "./Input/TextInput";
import { useCalegStore } from "../Views/Pages/MasterData/store/caleg";
import InputLoc from "./Input/InputLoc";
import { dapils, partais } from "../Config/constanta";

export default function ModalAddCaleg(props) {
  const [state, action] = useCalegStore();
  const [input, setInput] = useState({
    noUrut: "",
    name: "",
    idPartai: "",
  });
  const [dapil, setDapil] = useState({ code: "", label: "" });
  const [partai, setPartai] = useState({ code: "", no: 0, label: "" });

  const handleChangeFilter = (key1, key2, value, reason) => {
    if (reason === "clear") {
      key1(key2);
    } else {
      key1(value);
    }
  };

  function onChangeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  const submitPass = async () => {
    Swal.showLoading();
    const res = await action.addData({
      category: props.category,
      idDapil: dapil.code,
      idPartai: partai.code,
      ...input,
    });
    if (res) {
      Swal.close();
      await action.loadData();
      Swal.fire({
        title: "Sukses tambah data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.close();
      Swal.fire({
        title: "Gagal tambah data",
        timer: 2000,
        icon: "error",
      });
    }
    setInput({
      noUrut: "",
      name: "",
    });
  };

  function submit(event) {
    event.preventDefault();
    props.handleClose();
    submitPass();
  }

  return (
    <div>
      <Dialog sx={{ borderRadius: 20 }} open={props.open}>
        <DialogTitle sx={{ m: "auto" }}>Tambah Calon</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <TextInput
              label="Nomor Urut"
              onChange={onChangeInput}
              name="noUrut"
              value={input.noUrut}
              required={true}
            />
            <TextInput
              label="Nama Calon/Paslon"
              onChange={onChangeInput}
              name="name"
              value={input.name}
              required={true}
            />
            <InputLoc
              label="Partai"
              onChange={(event, newValue, reason) => {
                handleChangeFilter(
                  setPartai,
                  { code: "", no: "", label: "" },
                  newValue,
                  reason
                );
              }}
              value={partai}
              options={partais}
              required={true}
            />
            {props.category === "dprd kota" && (
              <InputLoc
                label="Dapil"
                onChange={(event, newValue, reason) => {
                  handleChangeFilter(
                    setDapil,
                    { code: "", label: "" },
                    newValue,
                    reason
                  );
                }}
                value={dapil}
                options={dapils}
                required={true}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} variant="contained" sx={{ borderRadius: 3 }}>
            Simpan
          </Button>
          <Button
            onClick={props.handleClose}
            sx={{ borderRadius: 3 }}
            variant="outlined"
          >
            Batal
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
