import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/system/Box";
import { Tab, Tabs } from "@mui/material";
import TabelCalon from "./TabelCalon";
import TabelCalonDewan from "./TabelCalonDewan";

const menus = [
  { label: "Presiden", tipe: "presiden" },
  { label: "Gubernur", tipe: "gubernur" },
  { label: "Walikota", el: "walikota" },
  { label: "DPD", tipe: "dpd" },
  { label: "DPR RI", tipe: "dprri" },
  { label: "DPRD Prov", tipe: "dprdprov" },
  { label: "DPRD Kota", tipe: "dprdkota" },
];

export default function MasterData() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ p: "20px", borderRadius: "20px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "20px" }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
        >
          {menus.map((e, i) => (
            <Tab
              sx={{
                fontSize: "14px",
                textTransform: "none",
                fontWeight: 600,
                "&:hover": {
                  color: "#fe5000",
                  opacity: 1,
                },
                "&.Mui-selected": {
                  color: "#fe5000",
                  fontWeight: 600,
                },
              }}
              label={e.label}
              key={i}
              value={i}
            />
          ))}
        </Tabs>
      </Box>
      {value < 4 ? (
        <TabelCalon category={menus[value].tipe} />
      ) : (
        <TabelCalonDewan category={menus[value].tipe} />
      )}
    </Paper>
  );
}
