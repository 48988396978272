import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import { Divider, Stack } from "@mui/material";
import TextInput from "./Input/TextInput";
import { useCalonStore } from "../Views/Pages/MasterData/store/calon";

export default function ModalEditCalon(props) {
  const [state, action] = useCalonStore();
  const [input, setInput] = useState({
    noUrut: "",
    name: "",
  });

  function onChangeInput(event) {
    let { name, value } = event.target;
    setInput({
      ...input,
      [name]: value,
    });
  }

  const submitPass = async () => {
    Swal.showLoading();
    const res = await action.editData(props.data.id, {
      ...input,
    });
    if (res) {
      Swal.close();
      await action.loadData();
      Swal.fire({
        title: "Sukses edit data",
        timer: 2000,
        icon: "success",
      });
    } else {
      Swal.close();
      Swal.fire({
        title: "Gagal edit data",
        timer: 2000,
        icon: "error",
      });
    }
    setInput({
      noUrut: "",
      name: "",
    });
  };

  useEffect(() => {
    setInput({
      noUrut: props.data?.noUrut ?? "-",
      name: props.data?.name ?? "-",
    });
  }, [props.data]);

  function submit(event) {
    event.preventDefault();
    props.handleClose();
    submitPass();
  }

  return (
    <div>
      <Dialog sx={{ borderRadius: 20 }} open={props.open}>
        <DialogTitle sx={{ m: "auto" }}>Edit Calon</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <TextInput
              label="Nomor Urut"
              onChange={onChangeInput}
              name="noUrut"
              value={input.noUrut}
              required={true}
            />
            <TextInput
              label="Nama Calon/Paslon"
              onChange={onChangeInput}
              name="name"
              value={input.name}
              required={true}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={submit} variant="contained" sx={{ borderRadius: 3 }}>
            Simpan
          </Button>
          <Button
            onClick={props.handleClose}
            sx={{ borderRadius: 3 }}
            variant="outlined"
          >
            Batal
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
