/* eslint-disable no-unused-vars */
import { createStore, createHook } from "react-sweet-state";
// eslint-disable-next-line no-unused-vars
import {
  editCalonApi,
  addCalonApi,
  viewCalonApi,
  deleteCalonApi,
} from "../api";

const Store = createStore({
  // value of the store on initialisation
  initialState: {
    isLoading: false,
    limit: 10,
    skip: 0,
    search: "",
    data: null,
    category: "presiden",
    total: 0,
    page: 0,
    error: null,
  },
  // actions that trigger store mutation
  actions: {
    updateStore:
      (newState) =>
      async ({ setState, getState }) => {
        setState(newState);
      },
    loadData:
      () =>
      async ({ setState, getState }) => {
        setState({ isLoading: true, error: null });
        const { search, page, category } = getState();
        try {
          const resp = await viewCalonApi({
            search,
            page,
            category,
          });
          if (resp.data && resp.data.code === 200) {
            setState({
              data: resp.data.payload.result,
              total: resp.data.payload.totalPage,
              page: resp.data.payload.page,
            });
          }
          setState({ isLoading: false });
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
        }
      },
    addData:
      (form) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await addCalonApi(form);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
    editData:
      (id, form) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await editCalonApi(id, form);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },

    deleteData:
      (id) =>
      async ({ setState }) => {
        setState({ isLoading: true });
        try {
          const res = await deleteCalonApi(id);
          if (res && res.status === 200) {
            setState({
              isLoading: false,
            });
            return true;
          } else {
            return false;
          }
        } catch (err) {
          setState({
            isLoading: false,
            error: err.message,
          });
          return null;
        }
      },
  },
});

export const useCalonStore = createHook(Store);
