import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function Searchbar({
  placeholder,
  state,
  action,
  handleChange,
  handleSubmit,
  value,
}) {
  const handleSubmitSearch = (event) => {
    event.preventDefault();
    action.updateStore({
      skip: 0,
    });
    action.loadData();
  };

  const handlechangeSearch = (event) => {
    action.updateStore({
      search: event.target.value,
    });
  };

  return (
    <Box
      component="form"
      sx={{
        p: "4px 4px",
        display: "flex",
        borderRadius: "12px",
        alignItems: "center",
        minWidth: 260,
        border: "1px solid rgba(224, 224, 224, 1)",
      }}
      onSubmit={handleSubmit ?? handleSubmitSearch}
    >
      <InputBase
        size="small"
        value={value ? value : state?.search ?? ""}
        onChange={handleChange ?? handlechangeSearch}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder ?? "Cari Data"}
        inputProps={{ "aria-label": "search google maps" }}
      />
      <IconButton type="submit" sx={{ px: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Box>
  );
}
